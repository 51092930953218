<!--
 * @Author: mulingyuer
 * @Date: 2021-05-25 10:32:24
 * @LastEditTime: 2021-08-26 09:40:32
 * @LastEditors: aleaner
 * @Description: 专题管理
 * @FilePath: \article-admin\views\article-subject\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page article-subject">
    <div class="head">
      <div class="head-group-btn top-btn">
        <el-button type="primary" size="small" @click="onAdd">新增</el-button>
      </div>
      <SubjectFilter :filter-change="onFilter" :exportData="postData" />
    </div>
    <div class="list-content content" v-loading="loading">
      <list-tabs v-model="postData.tab" :tabs="tabArr" @tab-click="handleTabClick" />
      <div class="list-btn">
        <el-button size="small" @click="onMultipleDelete">删除</el-button>
        <el-button v-if="postData.tab==='deleted'" size="small" type="success" @click="onMultipleReduction">还原
        </el-button>
      </div>
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe @sort-change="sortChange"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column fixed="right" :width="postData.tab==='deleted'?250:200" label="操作">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <el-button type="text" size="mini" @click="onArticleList(row.id)">
              文章列表</el-button>
            <el-button v-if="row.top_status <= 0" type="text" size="mini" @click="onSetTop(row)">
              置顶</el-button>
            <el-button v-if="row.top_status > 0" type="text" size="mini" @click="onCancelTop(row.id)">
              取消置顶</el-button>
            <el-button v-if="postData.tab==='deleted'" type="text" size="mini" @click="onReduction(row.id)">
              还原</el-button>
            <el-divider direction="vertical" />
            <el-dropdown @command="handleClickRow($event, row.id)">
              <span class="el-dropdown-link">更多</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in moreHandleList" :key="index"
                  :command="item.command">{{ item.name }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column v-for="th in thead[postData.tab]" :key="th.prop" :prop="th.prop" :label="th.label"
          :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed" :show-overflow-tooltip="th.tooltip">
          <template slot-scope="{row}">
            <!-- 文章数 -->
            <template v-if="th.prop==='articles_count'">
              {{row[th.prop] | placeholder}}
              <i class="icon el-icon-circle-plus-outline" @click="onAddArticle(row.id)"></i>
            </template>
            <!-- 排序 -->
            <PopoverInputEdit v-else-if="th.prop==='sort'" v-model="row.sort" input-type="number"
              :validator="validator" @confirm="changeStatus(row)" />
            <!-- 显示 -->
            <template v-else-if="th.prop==='status'">
              <el-checkbox v-model="row[th.prop]" :true-label="1" :false-label="0"
                @change="onStatusChange(row.id)"></el-checkbox>
            </template>
            <!-- 其他 -->
            <template v-else>{{row[th.prop] | placeholder}}</template>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
    </div>
    <!-- 添加文章弹窗 -->
    <AddArticleDialog v-model="openAddArticleDialog" :topicId="topicId" :complete="getSubjectList" />
    <!-- 置顶弹窗 -->
    <SetTopDialog v-model="openSetTopDialog" :setTopData="setTopData" :updateList="getSubjectList" />
  </div>
</template>

<script>
  import SubjectFilter from "../../components/article-subject/index/Filter";
  import Pagination from "@/base/components/Default/Pagination";
  import AddArticleDialog from "../../components/article-subject/index/AddArticleDialog";
  import {
    cancelTop,
    changeStatus,
    hardDelete,
    putBack,
    softDelete,
    subjectList,
    updateSort
  } from "../../api/article-subject/index";
  import SetTopDialog from "../../components/article-subject/index/SetTopDialog.vue";
  import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";

  export default {
  data() {
    const normal = [
      { label: "排序", prop: "sort", minWidth: 100 },
      { label: "专题名称", prop: "name", minWidth: 240, tooltip: true },
      { label: "分类", prop: "category_name", minWidth: 100 },
      { label: "栏目数", prop: "columns_count", minWidth: 100 },
      { label: "文章数", prop: "articles_count", minWidth: 100 },
      { label: "浏览数", prop: "view_count", minWidth: 100 },
      { label: "创建人", prop: "author_name", minWidth: 100 },
      {
        label: "创建时间",
        prop: "create_time",
        minWidth: 180,
        sort: "custom",
      },
      // { label: "显示", prop: "status", minWidth: 80 },
    ];
    return {
      loading: false, //加载中
      //上传的数据
      postData: {
        tab: "normal",
      },
      //tab数据
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "置顶", name: "top" },
        { label: "回收站", name: "deleted" },
      ],
      //表头
      thead: {
        normal: normal,
        deleted: normal,
        top: [
          { label: "排序", prop: "sort", minWidth: 80 },
          { label: "专题名称", prop: "name", minWidth: 180, tooltip: true },
          {
            label: "置顶位置",
            prop: "top_position_text",
            minWidth: 180,
            tooltip: true,
          },
          { label: "状态", prop: "top_status_text", minWidth: 100 },
          {
            label: "开始时间",
            prop: "top_start_text",
            minWidth: 180,
            sort: "custom",
          },
          {
            label: "结束时间",
            prop: "top_end_text",
            minWidth: 180,
            sort: "custom",
          },
        ],
      },
      tbody: [], //表格数据
      pageData: {}, //分页数据
      /* 行内编辑数字验证 */
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      // 展开更多配置
      moreHandleList: [
        { name: "编辑信息", command: "onEdit" },
        { name: "删除", command: "onDelete" },
      ],
      setTopData: {
        top_position: [],
        top_start_time: "",
        top_end_time: "",
      },
      openSetTopDialog: false,
      activeSubjectIds: [], //多选选择的id
      openAddArticleDialog: false, //是否显示添加文章弹窗
      topicId: "", //添加文章弹窗用的专题id
    };
  },
  methods: {
    //获取专题列表
    getSubjectList(pageData) {
      this.loading = true;
      subjectList(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 点击下拉菜单
    handleClickRow(event, id) {
      this[event](id);
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      this.getSubjectList();
    },
    //tab切换
    handleTabClick() {
      //tab name已经v-model绑定到postData
      Object.assign(this.postData, { page: 1 });
      this.getSubjectList();
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getSubjectList();
    },
    //表格排序事件
    sortChange({ prop, order }) {
      const is_desc = order === "ascending" ? 0 : 1;
      Object.assign(this.postData, { is_desc, order_by: prop }, { page: 1 });
      this.getSubjectList();
    },
    //表格多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.activeSubjectIds = arr.map((item) => item.id);
      } else {
        this.activeSubjectIds = [];
      }
    },
    // 更新列表状态
    changeStatus({ id, sort }) {
      const data = { id, sort };
      this.loading = true;
      // 更新排序状态
      updateSort(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.getSubjectList();
        })
        .catch(() => {
          this.getSubjectList();
        });
    },
    // 专题置顶
    onSetTop(row) {
      this.setTopData = row;
      this.openSetTopDialog = true;
    },
    // 取消置顶
    onCancelTop(id) {
      this.loading = true;
      cancelTop({ id })
        .then((res) => {
          this.$message.success(res.msg);
          this.getSubjectList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //单个删除
    onDelete(id, multiple = false) {
      let infoText = `您是否要删除${multiple ? "选中的" : "该条"}专题数据？`;
      let api = softDelete;
      if (this.postData.tab === "deleted") {
        infoText += "删除后将不可恢复！";
        api = hardDelete;
      }
      this.$confirm(infoText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.loading = true;
          const postData = { id };
          api(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              //更新
              this.getSubjectList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    //多选删除
    onMultipleDelete() {
      if (this.activeSubjectIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onDelete(this.activeSubjectIds, true);
    },
    //单个还原
    onReduction(id) {
      this.loading = true;
      const postData = { id };
      putBack(postData)
        .then((res) => {
          const { msg } = res;
          this.$message.success(msg);
          this.getSubjectList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //多选还原
    onMultipleReduction() {
      if (this.activeSubjectIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onReduction(this.activeSubjectIds);
    },
    //显示事件
    onStatusChange(id) {
      this.loading = true;
      const postData = { id };
      changeStatus(postData)
        .then((res) => {
          const { msg } = res;
          this.$message.success(msg);
          this.getSubjectList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //新增
    onAdd() {
      this.$router.push({ name: "ArticleSubjectAddEdit" });
    },
    //编辑
    onEdit(id) {
      this.$router.push({ name: "ArticleSubjectAddEdit", query: { id } });
    },
    //添加文章
    onAddArticle(id) {
      this.topicId = id;
      this.openAddArticleDialog = true;
    },
    //前往文章列表
    onArticleList(id) {
      this.$router.push({ name: "SubjectArticleList", params: { id } });
    },
  },
  created() {
    this.getSubjectList();
  },
  components: {
    SubjectFilter,
    Pagination,
    AddArticleDialog,
    SetTopDialog,
    PopoverInputEdit,
  },
};
</script>

<style lang="scss" scoped>
.article-subject {
  .content {
    .thead-light {
      .icon {
        margin-left: 5px;
        cursor: pointer;
        padding: 5px;
        &:hover {
          color: #3576ff;
        }
        &:active {
          opacity: 0.7;
        }
      }
    }
  }
}

.el-dropdown-link {
  color: #3576ff;
  font-size: 12px;
}
</style>
