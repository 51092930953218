<!--
 * @Author: dongjia
 * @Date: 2021-06-11 13:58:30
 * @LastEditTime: 2021-06-11 15:13:22
 * @LastEditors: aleaner
 * @Description: 文章专题管理-置顶编辑弹窗
 * @FilePath: \article-admin\components\article-subject\index\SetTopDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="专题置顶" :visible.sync="openDialog" width="600px" :show-close="true" class="dialog-vertical"
    @close="cancel('setTop')">
    <el-form size="medium" :model="form" :rules="rules" ref="setTop" label-width="100px">
      <el-form-item prop="top_position" label="置顶位置：">
        <el-checkbox-group v-model="form.top_position">
          <el-checkbox v-for="item in topPositionArr" :label="item.value" :key="item.value">{{item.name}}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="置顶时间：" prop="top_time">
        <DateTimePicker v-model="form.top_time" />
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('setTop')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import DateTimePicker from "@/base/components/Form/DateTimePicker.vue";
  import {setTop} from "../../../api/article-subject/index";

  export default {
  components: { DateTimePicker },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    setTopData: {
      type: Object,
      default: () => {},
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        top_position: [
          {
            type: "array",
            required: true,
            message: "请设置置顶位置",
            trigger: "change",
          },
        ],
        top_time: [
          { required: true, validator: this.passTopTime, trigger: "change" },
        ],
      },
      form: {
        top_position: [],
        top_start_time: 0,
        top_end_time: 0,
        top_time: ["", ""],
      },
      topPositionArr: [
        { value: 1, name: "专题列表页" },
        { value: 2, name: "全部分类页" },
        { value: 3, name: "所属分类页" },
      ],
      saveLoading: false,
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.setTop.resetFields();
          this.form = { ...this.form, ...this.setTopData };
          this.form.top_time = [
            this.form.top_start_time,
            this.form.top_end_time,
          ];
        });
      }
    },
  },
  methods: {
    // 验证置顶时间
    passTopTime(rule, value, callback) {
      if (value[0] === "" || value[1] === "") {
        callback(new Error("请设置置顶时间"));
      } else {
        callback();
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const data = { ...this.form };
          data.top_start_time = data.top_time[0];
          data.top_end_time = data.top_time[1];
          setTop(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style>
</style>
